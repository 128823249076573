import React from "react";
import "./not-found.component.css";

export default function NotFoundComponent() {
  return (
    <div id="not-found">
      <h1>404</h1>
      <p>page not found</p>
    </div>
  );
}
